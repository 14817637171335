<template>
  <div class="w-100" v-if="$isLoading">
    <form class="form-box">
      <b-row class="no-gutters bg-white">
        <b-col>
          <b-button v-b-toggle.collapse-general class="collapse-btn">
            ข้อมูลทั่วไป
            <font-awesome-icon
              icon="chevron-right"
              class="icon float-right mt-1"
            />
            <font-awesome-icon
              icon="chevron-down"
              class="icon float-right mt-1"
            />
          </b-button>
          <b-collapse id="collapse-general" visible>
            <b-card class="card-box">
              <b-row>
                <b-col cols="6">
                  <InputSelect
                    class="w-100"
                    title="ประเภทโปรโมชั่น"
                    name="promotionId"
                    valueField="id"
                    isRequired
                    textField="name"
                    v-bind:options="type"
                    @onDataChange="onChangePromotionTypeId"
                    v-model="form.promotionTypeId"
                  />
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'ชื่อโปรโมชั่น'"
                    :placeholder="'ชื่อโปรโมชั่น'"
                    type="text"
                    isRequired
                    name="name"
                    v-model="form.name"
                    :isValidate="$v.form.name.$error"
                    :v="$v.form.name"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <DateTimePicker
                    textFloat="วันที่เริ่มโปรโมชั่น"
                    type="datetime"
                    isTime
                    isRequired
                    placeholder="กรุณาเลือกวันที่และเวลา"
                    v-model="form.startDateTime"
                    :isValidate="$v.form.startDateTime.$error"
                    :v="$v.form.startDateTime"
                  />
                </b-col>
                <b-col md="6">
                  <DateTimePicker
                    textFloat="วันที่สิ้นสุดโปรโมชั่น"
                    type="datetime"
                    isTime
                    isRequired
                    placeholder="กรุณาเลือกวันที่และเวลา"
                    v-model="form.endDateTime"
                    :isValidate="$v.form.endDateTime.$error"
                    :v="$v.form.endDateTime"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <InputText
                    textFloat="ลำดับการแสดง"
                    placeholder="ลำดับการแสดง"
                    type="text"
                    name="sortOrder"
                    @onKeypress="isNumber($event)"
                    v-model="form.sortOrder"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col md="6 mt-2">
                  <label for=""
                    >ประเภทสมาชิก/ระดับสมาชิก
                    <span class="text-danger ml-1">*</span></label
                  >
                  <div
                    class="d-flex justify-content-start align-items-center mx-2"
                    v-for="(itemMembertype, key) of membertype"
                    :key="`membertype-${key}`"
                  >
                    <b-form-group :label="itemMembertype.roleNameTH">
                      <b-form-checkbox-group v-model="defaultArray">
                        <b-form-checkbox
                          v-for="(tier, index) of itemMembertype.tiers"
                          :key="`tiers-${index}`"
                          :value="tier.id"
                          @input="
                            (val) =>
                              onDataChangeTiers(val, tier, itemMembertype)
                          "
                          >{{ tier.name }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                  <span v-if="checkMemberObj" class="text-error"
                    >กรุณาเลือกข้อมูล</span
                  >
                </b-col>
                <b-col cols="6">
                  <InputText
                    class="mb-4 mt-1"
                    :textFloat="'จำนวนสิทธ์สำหรับใช้งาน'"
                    :placeholder="'ระบุจำนวนที่ต้องการ หรือ ละเว้นเพื่อไม่จำกัดจำนวน'"
                    type="number"
                    v-model="form.count"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col md="6" sm="12">
                  <div class="d-flex">
                    <label for="" class="w-25"
                      >จำกัดการใช้งาน<span class="text-danger ml-1"
                        >*</span
                      ></label
                    >
                    <b-form-radio
                      class="ml-2"
                      name="isLimit"
                      v-model="form.isLimit"
                      :value="true"
                      >ใช้ได้ 1 บัญชี / 1 ครั้ง</b-form-radio
                    >
                    <b-form-radio
                      class="mx-4"
                      name="isLimit"
                      v-model="form.isLimit"
                      :value="false"
                      >ไม่จำกัด</b-form-radio
                    >
                  </div>
                  <span v-if="$v.form.isLimit.$error" class="text-error"
                    >กรุณาเลือกข้อมูล</span
                  >
                </b-col>
                <b-col md="6" sm="12">
                  <!-- <div class="d-flex align-items-center">
                    <span
                      >สามารถเข้าเงื่อนไขได้มากกว่า 1 ครึ่ง / ต่อ 1
                      คำสั่งซื้อ</span
                    >
                    <b-form-checkbox
                      switch
                      v-model="form.isRepeating"
                      class="radio-active ml-4"
                      size="lg"
                    >
                      <span class="ml-2 main-label">
                        {{ form.isRepeating ? "เปิด" : "ปิด" }}
                      </span>
                    </b-form-checkbox>
                  </div> -->
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>

          <b-button v-b-toggle.collapse-discount class="collapse-btn">
            เงื่อนไขส่วนลด
            <font-awesome-icon
              icon="chevron-right"
              class="icon float-right mt-1"
            />
            <font-awesome-icon
              icon="chevron-down"
              class="icon float-right mt-1"
            />
          </b-button>
          <b-collapse id="collapse-discount" visible>
            <b-card class="card-box">
              <div v-if="form.promotionTypeId == 1">
                <b-row>
                  <b-col cols="6">
                    <b-row class="d-flex align-items-center">
                      <b-col cols="9">
                        <InputText
                          class="mb-4 mt-2"
                          :textFloat="'ขั้นต่ำในการซื้อสินค้า'"
                          :placeholder="'ระบุจำนวนขัั้นต่ำ'"
                          type="number"
                          isRequired
                          v-model="min"
                          :isValidate="$v.min.$error"
                          :v="$v.min"
                        />
                      </b-col>
                      <b-col cols="3">
                        <InputSelect
                          class="w-100 mt-4"
                          valueField="id"
                          isRequired
                          textField="name"
                          v-bind:options="quantityType"
                          @onDataChange="(value) => (quantitySelect = value)"
                          v-model="quantitySelect"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <label for=""
                      >ประเภทส่วนลด<span class="text-danger ml-1"
                        >*</span
                      ></label
                    >
                    <div class="d-flex align-items-center mt-3">
                      <b-form-radio
                        class="mr-4"
                        name="promotionDiscountId"
                        v-model="form.promotionDiscountId"
                        :value="1"
                        >บาท</b-form-radio
                      >
                      <b-form-radio
                        class="mr-4"
                        name="promotionDiscountId"
                        v-model="form.promotionDiscountId"
                        :value="2"
                        >เปอร์เซ็นต์</b-form-radio
                      >
                      <b-form-radio
                        class="mr-4"
                        name="promotionDiscountId"
                        v-model="form.promotionDiscountId"
                        :value="3"
                        >กำหนดราคา</b-form-radio
                      >
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <ProductList
                    ref="ProductList"
                    @openModal="openModal"
                    @deleteData="deleteData"
                    :fields="fields"
                    :productGroups="productGroups"
                    :promotionDiscountId="form.promotionDiscountId"
                    :v="$v"
                  />
                  <span
                    v-if="productGroups.length === 0 && checkObj"
                    class="text-error ml-3"
                    >กรุณาเพิ่มรายการสินค้า</span
                  >
                </b-row>
              </div>
              <div
                v-else-if="
                  form.promotionTypeId == 5 ||
                  form.promotionTypeId == 7 ||
                  form.promotionTypeId == 8 ||
                  form.promotionTypeId == 9
                "
              >
                <div class="d-flex justify-content-between">
                  <label for="">กลุ่มเงื่อนไขส่วนลด</label>
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <label for="" class="text-nowrap mr-2"
                        >เงื่อนไขการทำงาน<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <div
                        class=""
                        v-for="(i, index) of expression"
                        :key="`expression-${index}`"
                      >
                        <b-form-radio
                          class="text-nowrap ml-4"
                          name="expression"
                          :value="i.id"
                          v-model="form.expressionId"
                          >{{ i.name }}</b-form-radio
                        >
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <span
                        v-if="$v.form.expressionId.$error"
                        class="text-error ml-3"
                        >กรุณาเลือกข้อมูล</span
                      >
                    </div>
                  </div>
                </div>
                <div class="w-100 mt-3">
                  <div
                    class="toggle border p-4"
                    v-for="(e, index) of form.conditionGroupProducts"
                    :key="`conditionGroupProducts-${index}`"
                  >
                    <b-row>
                      <b-col cols="5">
                        <div
                          class="border"
                          v-for="(i, key) of e.subConditions"
                          :key="`subConditions-${key}`"
                        >
                          <b-button
                            v-b-toggle="'accordion-' + key + index"
                            class="collapse-btn"
                          >
                            กลุ่มที่ {{ key + 1 }}
                            <font-awesome-icon
                              icon="chevron-right"
                              class="icon float-right mt-1"
                            />
                            <font-awesome-icon
                              icon="chevron-down"
                              class="icon float-right mt-1"
                            />
                          </b-button>
                          <b-collapse
                            :id="'accordion-' + key + index"
                            visible
                            class="px-3 pt-3"
                          >
                            <div class="mt-1">
                              <InputSelect
                                class="w-100"
                                title="ขอบเขตเงื่อนไข"
                                valueField="id"
                                isRequired
                                textField="name"
                                v-bind:options="scopeType"
                                @onDataChange="
                                  (value) => (i.promotionScopeId = value)
                                "
                                v-model="i.promotionScopeId"
                              />
                            </div>
                            <div class="mt-3">
                              <InputSelect
                                class="w-100"
                                title="เงื่อนไข"
                                valueField="id"
                                isRequired
                                textField="name"
                                v-bind:options="conditionType"
                                @onDataChange="
                                  (value) => (i.promotionConditionId = value)
                                "
                                v-model="i.promotionConditionId"
                              />
                            </div>
                            <div class="mt-3">
                              <InputText
                                class="mb-4 mt-2"
                                :textFloat="'ค่า'"
                                :placeholder="'กรุณาระบุจำนวน'"
                                type="text"
                                isRequired
                                name="name"
                                v-model="i.value"
                                :isValidate="
                                  $v.form.conditionGroupProducts.$each.$iter[
                                    index
                                  ].subConditions.$each.$iter[key].value.$error
                                "
                                :v="
                                  $v.form.conditionGroupProducts.$each.$iter[
                                    index
                                  ].subConditions.$each.$iter[key].value
                                "
                              />
                            </div>
                            <div class="d-flex justify-content-end pb-3">
                              <b-button
                                class="btn-danger"
                                :disabled="e.subConditions.length <= 1"
                                @click="spliceGroup(index, key)"
                                >ลบกลุ่มเงื่อนไข</b-button
                              >
                            </div>
                          </b-collapse>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                          <b-button
                            class="btn-cancel ml-3"
                            @click="handleGroup(index)"
                            >เพิ่มกลุ่มเงื่อนไข</b-button
                          >
                        </div>
                      </b-col>
                      <b-col cols="7">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="d-flex">
                            <b-form-radio
                              v-if="form.promotionTypeId != 7"
                              class="mr-3"
                              v-model="e.productSelectTypeId"
                              :value="0"
                              @change="setArray(index)"
                              >สินค้าทั้งหมด</b-form-radio
                            >
                            <b-form-radio
                              class="mr-3"
                              v-model="e.productSelectTypeId"
                              :value="1"
                              >สินค้าที่เลือก</b-form-radio
                            >
                            <b-form-radio
                              v-if="form.promotionTypeId != 7"
                              class="mr-3"
                              v-model="e.productSelectTypeId"
                              :value="2"
                              >ไม่รวมสินค้าในรายการ</b-form-radio
                            >
                          </div>
                          <b-dropdown
                            id="dropdown-left"
                            right
                            :text="'ตัวเลือกเพิ่มเติม'"
                            variant="outline-dark"
                            class="lang-dd"
                          >
                            <b-dropdown-item-button
                              :disabled="e.productSelectTypeId == 0"
                              @click="openModal(index)"
                            >
                              เพิ่มสินค้า
                            </b-dropdown-item-button>
                          </b-dropdown>
                        </div>
                        <b-row>
                          <ProductList
                            :fields="fieldsProduct"
                            :productGroups="e.details"
                            :index="index"
                            :hideHeader="true"
                            :hidePaginate="true"
                            :type="form.promotionTypeId"
                            @openModal="openModal"
                            @deleteData="deleteData"
                            :promotionDiscountId="form.promotionDiscountId"
                            :v="$v"
                          />
                          <span
                            v-if="
                              e.details.length === 0 &&
                              checkObj &&
                              e.productSelectTypeId !== 0
                            "
                            class="text-error ml-3"
                            >กรุณาเพิ่มรายการสินค้า</span
                          >
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <hr />
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <b-button
                            class="btn-danger"
                            @click="spliceGroup(index)"
                            :disabled="form.conditionGroupProducts.length <= 1"
                            >ลบกลุ่มเงื่อนไข</b-button
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                  <b-button class="btn-main ml-3" @click="handleGroup()"
                    >กลุ่มเงื่อนไขใหม่</b-button
                  >
                </div>
              </div>
              <div v-else-if="form.promotionTypeId == 6">
                <div class="d-flex justify-content-between">
                  <label for="">ขั้นต่ำเงื่อนไขส่วนลด</label>
                  <b-button
                    class="button btn-cancel"
                    @click="openModalProvince()"
                    >เพิ่มปลายทาง</b-button
                  >
                  <!-- <div class="d-flex flex-column">
                    <div class="d-flex">
                      <label for="" class="text-nowrap mr-2"
                        >เงื่อนไขการทำงาน<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <div
                        class=""
                        v-for="(i, index) of expression"
                        :key="`expression-${index}`"
                      >
                        <b-form-radio
                          class="text-nowrap ml-4"
                          name="expression"
                          :value="i.id"
                          v-model="form.expressionId"
                          >{{ i.name }}</b-form-radio
                        >
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <span
                        v-if="$v.form.expressionId.$error"
                        class="text-danger ml-3"
                        >กรุณาเลือกข้อมูล</span
                      >
                    </div>
                  </div> -->
                </div>
                <b-row class="mt-2">
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="12">
                        <InputText
                          class="mb-3"
                          :textFloat="'มูลค่าคำสั่งซื้อ'"
                          :placeholder="'กรุณาระบุจำนวน'"
                          type="number"
                          isRequired
                          v-model="form.minimumValue"
                          :isValidate="$v.form.minimumValue.$error"
                          :v="$v.form.minimumValue"
                        />
                      </b-col>
                      <b-col cols="12">
                        <InputText
                          class="mb-3"
                          :textFloat="'จำนวนชิ้น'"
                          :placeholder="'ระบุจำนวนที่ต้องการ หรือ ละเว้นเพื่อไม่จำกัดจำนวน'"
                          type="number"
                          v-model="form.productQuantity"
                        />
                      </b-col>
                      <b-col cols="12">
                        <label for=""
                          >ขนาดของพัสดุ<span class="text-danger ml-1"
                            >*</span
                          ></label
                        >
                        <div class="d-flex size">
                          <InputText
                            class="mb-4"
                            :textFloat="''"
                            :placeholder="'ความกว้าง (ซม.)'"
                            type="text"
                            isRequired
                            name="name"
                            v-model="form.maximumWidth"
                            :isValidate="$v.form.maximumWidth.$error"
                            :v="$v.form.maximumWidth"
                          />
                          <InputText
                            class="mb-4"
                            :textFloat="''"
                            :placeholder="'ความยาว (ซม.)'"
                            type="text"
                            isRequired
                            name="name"
                            v-model="form.maximumLength"
                            :isValidate="$v.form.maximumLength.$error"
                            :v="$v.form.maximumLength"
                          />
                          <InputText
                            class="mb-4"
                            :textFloat="''"
                            :placeholder="'ความสูง (ซม.)'"
                            type="text"
                            isRequired
                            name="name"
                            v-model="form.maximumHeight"
                            :isValidate="$v.form.maximumHeight.$error"
                            :v="$v.form.maximumHeight"
                          />
                          <InputText
                            class="mb-4"
                            :textFloat="''"
                            :placeholder="'น้ำหนัก (กรัม)'"
                            type="text"
                            isRequired
                            name="name"
                            v-model="form.maximumWeight"
                            :isValidate="$v.form.maximumWeight.$error"
                            :v="$v.form.maximumWeight"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <InputTextArea
                      textFloat="ปลายทาง"
                      placeholder="ปลายทาง"
                      type="text"
                      rows="5"
                      isRequired
                      :disabled="true"
                      v-model="provinceSelect"
                      :isValidate="$v.provinceSelect.$error"
                      :v="$v.provinceSelect"
                    />
                    <!-- <InputSelect
                      class="w-100 gray"
                      valueField="id"
                      textField="name"
                      v-bind:options="province"
                      @onDataChange="(value) => handleProvince(value)"
                      v-model="text"
                    /> -->
                    <!-- <div class="d-flex" v-if="provinceSelect.length > 0">
                      <label class="option-select text-nowrap mr-1"
                        >รายการที่เลือก :
                      </label>
                      <div>
                        <span
                          class="mr-1"
                          v-for="(i, index) of provinceSelect"
                          :key="`provinceSelect-${index}`"
                          >{{ i }}
                          <strong
                            v-if="
                              provinceSelect.length > 1 &&
                              index !== provinceSelect.length - 1
                            "
                            >,</strong
                          ></span
                        >
                      </div>
                    </div> -->
                    <!-- <span
                      v-if="provinceSelect.length === 0 && checkObj"
                      class="text-error"
                      >กรุณากรอกข้อมูล</span
                    > -->
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-collapse>

          <b-button
            v-b-toggle.collapse-discountDetail
            class="collapse-btn"
            v-if="form.promotionTypeId != 1"
          >
            รายละเอียดส่วนลด
            <font-awesome-icon
              icon="chevron-right"
              class="icon float-right mt-1"
            />
            <font-awesome-icon
              icon="chevron-down"
              class="icon float-right mt-1"
            />
          </b-button>
          <b-collapse
            id="collapse-discountDetail"
            visible
            v-if="
              form.promotionTypeId == 5 ||
              form.promotionTypeId == 7 ||
              form.promotionTypeId == 9
            "
          >
            <b-card class="card-box">
              <b-row v-if="form.promotionTypeId == 9">
                <b-col cols="6">
                  <InputText
                    :textFloat="'จำนวนของแถมที่ได้รับ'"
                    :placeholder="'กรุณากรอกจำนวน'"
                    type="text"
                    isRequired
                    v-model="form.receiveNo"
                    :isValidate="$v.form.receiveNo.$error"
                    :v="$v.form.receiveNo"
                  />
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'จำนวนรอบครั้งที่แถม'"
                    :placeholder="'กรุณากรอกจำนวน'"
                    type="text"
                    isRequired
                    v-model="form.roundNo"
                    :isValidate="$v.form.roundNo.$error"
                    :v="$v.form.roundNo"
                  />
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="6">
                  <InputSelect
                    class="w-100"
                    title="ประเภทของส่วนลดที่ได้รับ"
                    name="promotionId"
                    valueField="id"
                    isRequired
                    textField="name"
                    v-bind:options="promotionDiscount"
                    @onDataChange="
                      (value) => (form.promotionDiscountId = value)
                    "
                    v-model="form.promotionDiscountId"
                  />
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'จำนวนชิ้นที่ได้รับต่อการเข้าเงื่อนไข 1 ครั้ง'"
                    :placeholder="'กรุณาระบุจำนวน'"
                    type="text"
                    isRequired
                    name="name"
                    v-model="form.productQuantity"
                    :isValidate="$v.form.productQuantity.$error"
                    :v="$v.form.productQuantity"
                  />
                </b-col>
                <b-col
                  cols="6"
                  v-if="form.promotionTypeId == 5 || form.promotionTypeId == 7"
                >
                  <InputText
                    :textFloat="'จำนวนรอบครั้งที่แถม'"
                    :placeholder="'กรุณากรอกจำนวน'"
                    type="text"
                    isRequired
                    v-model="form.roundNo"
                    :isValidate="$v.form.roundNo.$error"
                    :v="$v.form.roundNo"
                  />
                </b-col>
                <b-col cols="6" v-if="form.promotionTypeId == 7">
                  <div class="d-flex align-items-center">
                    <InputText
                      class="w-100"
                      :textFloat="'ระบุส่วนลดเพื่อมีผลกับทั้งหมด'"
                      :placeholder="'กรุณากรอกจำนวน'"
                      :disabled="productGroups.length == 0"
                      type="number"
                      v-model="all"
                    />
                    <b-button
                      class="btn-main ml-3 mt-2"
                      :disabled="productGroups.length == 0 || all == ''"
                      @click="setValue()"
                      >มีผลกับทั้งหมด</b-button
                    >
                  </div>
                </b-col>
                <b-col cols="12" v-if="form.promotionTypeId == 5">
                  <div class="d-flex align-items-start my-1">
                    <span class="font-weight-bold mt-1 w-0">
                      สินค้าที่ได้รับส่วนลดจะไม่ใช้เป็นเงื่อนไขกับโปรโมชั่นอื่น
                    </span>
                    <div>
                      <b-form-checkbox
                        switch
                        class="radio-active ml-3"
                        size="lg"
                        v-model="form.isApplyProductAsUsed"
                      >
                        <span class="ml-2 main-label">
                          {{ form.isApplyProductAsUsed ? "เปิด" : "ปิด" }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <ProductList
                  ref="ProductList"
                  @openModal="openModal"
                  @deleteData="deleteData"
                  :fields="form.promotionTypeId == 9 ? fieldsGWP : fields"
                  :productGroups="productGroups"
                  :type="form.promotionTypeId"
                  :promotionDiscountId="form.promotionDiscountId"
                  :isBusy="isBusyProduct"
                  :v="$v"
                />
                <span
                  v-if="productGroups.length === 0 && checkObj"
                  class="text-error ml-3"
                  >กรุณาเพิ่มรายการสินค้า</span
                >
              </b-row>
            </b-card>
          </b-collapse>
          <b-collapse
            id="collapse-discountDetail"
            visible
            v-if="form.promotionTypeId == 6"
          >
            <b-card class="card-box">
              <b-row>
                <b-col cols="6">
                  <InputSelect
                    class="w-100"
                    title="ประเภทของส่วนลดที่ได้รับ"
                    name="promotionId"
                    valueField="id"
                    isRequired
                    textField="name"
                    v-bind:options="promotionDiscount"
                    @onDataChange="
                      (value) => (form.promotionDiscountId = value)
                    "
                    v-model="form.promotionDiscountId"
                  />
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'มูลค่าส่วนลด'"
                    :placeholder="'กรุณาระบุจำนวน'"
                    type="text"
                    isRequired
                    name="name"
                    v-model="form.discountValue"
                    :isValidate="$v.form.discountValue.$error"
                    :v="$v.form.discountValue"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
          <b-collapse
            id="collapse-discountDetail"
            visible
            v-if="form.promotionTypeId == 8"
          >
            <b-card class="card-box">
              <b-row>
                <b-col cols="6">
                  <InputSelect
                    class="w-100"
                    title="ประเภทของส่วนลดที่ได้รับ"
                    name="promotionId"
                    valueField="id"
                    isRequired
                    textField="name"
                    v-bind:options="promotionDiscount"
                    @onDataChange="
                      (value) => (form.promotionDiscountId = value)
                    "
                    v-model="form.promotionDiscountId"
                  />
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'มูลค่าส่วนลด'"
                    :placeholder="'กรุณาระบุจำนวน'"
                    type="text"
                    isRequired
                    name="name"
                    v-model="form.discountValue"
                    :isValidate="$v.form.discountValue.$error"
                    :v="$v.form.discountValue"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <div class="d-flex flex-column">
                    <label for="" class="w-50">กำหนดโค้ทสำหรับใช้งาน</label>
                    <div class="d-flex mt-2">
                      <b-form-radio
                        class=""
                        name="isCodeRequired"
                        v-model="form.isCodeRequired"
                        :value="false"
                        >ไม่ต้องใช้โค้ท</b-form-radio
                      >
                      <b-form-radio
                        class="mx-4"
                        name="isCodeRequired"
                        v-model="form.isCodeRequired"
                        :value="true"
                        >ต้องใช้โค้ทเพื่อรับส่วนลด</b-form-radio
                      >
                    </div>
                  </div>
                </b-col>
                <b-col cols="6">
                  <InputText
                    :textFloat="'โค้ทส่วนลด'"
                    :placeholder="'กรุณาระบุโค้ท'"
                    type="text"
                    name="code"
                    :disabled="!form.isCodeRequired"
                    v-model="form.code"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>

          <b-row class="mt-3 mb-4">
            <b-col>
              <b-row class="px-3">
                <b-col
                  class="d-flex justify-content-between align-items-center"
                >
                  <div class="d-sm-flex m-3 align-items-center">
                    <label class="font-weight-bold main-label">
                      การใช้งาน
                      <span class="text-danger">*</span>
                    </label>
                    <div>
                      <b-form-checkbox
                        switch
                        class="radio-active ml-3"
                        size="lg"
                        v-model="form.enabled"
                      >
                        <span class="ml-2 main-label">
                          {{ form.enabled ? "เปิด" : "ปิด" }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <router-link :to="'/promotion'">
                      <b-button class="button btn-cancel ml-2"
                        >ย้อนกลับ</b-button
                      ></router-link
                    >
                    <b-button class="btn-main ml-3" @click="submitForm()"
                      >บันทึก</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
    <ModalSku
      ref="ModalSku"
      @handleData="handleData"
      :products="productGroups"
    />
    <ModalProvince
      ref="ModalProvince"
      @handleData="setProvince"
      :province="province"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import PromotionDetailsSection from "@/views/pages/promotion/components/PromotionDetailsSection";
import ProductList from "@/views/pages/promotion/components/ProductList";
import ModalProvince from "@/views/pages/promotion/components/ModalProvince";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import DateTimePicker from "@/components/inputs/DateTimePicker";
// import DatePickerFilter from "@/components/inputs/DatePickerFilter";
import ModalSku from "@/views/pages/product/components/ModalSku";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import InputTextArea from "@/components/inputs/InputTextArea";

const validateMinvalue = (value) => {
  if (parseInt(value) == 0) {
    return true;
  } else {
    return false;
  }
};
const validateProductGroups = (
  value,
  price,
  promotionTypeId,
  promotionDiscountId,
  firstCheck
) => {
  if (!firstCheck) return true;
  if (promotionTypeId == 1 || promotionTypeId == 5 || promotionTypeId == 7) {
    if (!value) return false;
    else if (promotionDiscountId == 1) {
      if (price - parseFloat(value) >= 0) return true;
    } else if (promotionDiscountId == 2) {
      if (value > 100) return false;
      else if ((price * (100 - parseFloat(value))) / 100 >= 0) return true;
    } else if (promotionDiscountId == 3) {
      if (value > price) return false;
      else if (parseFloat(value) >= 0) return true;
    }
    return false;
  } else return true;
};

import {
  required,
  requiredIf,
  minValue,
  minLength,
  decimal,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  components: {
    PromotionDetailsSection,
    ProductList,
    InputText,
    InputSelect,
    DateTimePicker,
    ModalSku,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    InputTextArea,
    ModalProvince,
  },
  data() {
    return {
      id: this.$route.params.id,
      checkObj: false,
      checkMemberObj: false,
      firstCheck: false,
      isBusyProduct: false,
      type: [],
      conditionType: [],
      promotionDiscount: [],
      expression: [],
      province: [],
      scopeType: [],
      membertype: [],
      provinceSelect: [],
      text: null,
      all: "",
      form: {
        id: this.$route.params.id,
        name: "",
        promotionTypeId: "",
        startDateTime: "",
        endDateTime: "",
        roundNo: 999,
        code: "",
        enabled: true,
        isLimit: false,
        isCodeRequired: false,
        isApplyProductAsUsed: false,
        isRepeating: false,
        minimumValue: "",
        productQuantity: "",
        promotionDiscountId: "",
        discountValue: "",
        count: null,
        expressionId: null,
        promotionMember: [],
        productDetail: [],
        destinationProviceIds: [],
        maximumLength: "",
        maximumWidth: "",
        maximumHeight: "",
        maximumWeight: "",
        conditionGroupProducts: [
          {
            id: 0,
            subConditions: [
              {
                id: 0,
                promotionConditionId: "",
                promotionScopeId: "",
                value: "",
              },
            ],
            productSelectTypeId: 0,
            details: [],
          },
        ],
      },
      quantityType: [
        {
          id: 0,
          name: "บาท",
        },
        {
          id: 1,
          name: "ชิ้น",
        },
      ],
      quantitySelect: "",
      min: 1,
      fieldsGWP: [
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-50px",
        },
      ],
      fields: [
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px",
        },
        {
          key: "price",
          label: "ราคา",
          class: "w-100px",
        },
        {
          key: "discount",
          label: "ส่วนลด",
          class: "w-100px",
        },
        {
          key: "rawPrice",
          label: "ราคาขาย",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-50px",
        },
      ],
      fieldsProduct: [
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-50px",
        },
      ],
      productGroups: [],
      productGroupsError: [],
      modalMessage: "",
      defaultArray: [],
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getData();
    await this.getScopeType();
    if (parseInt(this.$route.params.id) > 0) await this.getDetail();
    this.$isLoading = true;
  },
  computed: {
    ...mapState({
      promotionType: (state) => state.promotionType,
    }),
  },
  watch: {
    "form.count": function (e) {
      this.form.count = parseFloat(e);
    },
    "form.promotionMember": function (e) {
      this.checkMemberObj = false;
    },
    "form.promotionTypeId": function (e) {
      this.getScopeType();
      if (this.id == 0) {
        this.form.conditionGroupProducts = [];
        this.form.conditionGroupProducts[0] = {
          id: 0,
          subConditions: [
            {
              id: 0,
              promotionConditionId:
                this.conditionType.length > 0 ? this.conditionType[0].id : "",
              promotionScopeId:
                this.scopeType.length > 0 ? this.scopeType[0].id : "",
              value: "",
            },
          ],
          productSelectTypeId: 0,
          details: [],
        };
      }
    },
    quantitySelect: function (e) {
      if (e === 1) {
        this.form.productQuantity = parseFloat(this.min);
        delete this.form.minimumValue;
      } else {
        this.form.minimumValue = parseFloat(this.min);
        delete this.form.productQuantity;
      }
    },
    min: function (e) {
      if (this.quantitySelect === 1) {
        this.form.productQuantity = parseFloat(e);
        delete this.form.minimumValue;
      } else {
        this.form.minimumValue = parseFloat(e);
        delete this.form.productQuantity;
      }
    },
    productGroups: {
      async handler(e) {
        this.checkObj = false;
        let array = [];
        for (const i of e) {
          array.push({
            productId: i.id,
            discount: i.discount ? parseFloat(i.discount) : 0,
          });
        }
        this.form.productDetail = array;
      },
      deep: true,
    },
    provinceSelect: {
      async handler() {
        this.checkObj = false;
      },
      deep: true,
    },
  },
  validations() {
    return {
      min: { required, minValue: minValue(1) },
      all: { required, minValue: minValue(1) },
      provinceSelect: {
        required: requiredIf(function () {
          return this.form.promotionTypeId == 6;
        }),
      },
      form: {
        name: { required },
        startDateTime: { required },
        endDateTime: { required },
        isLimit: { required },
        expressionId: {
          required: requiredIf(function () {
            return (
              this.form.promotionTypeId == 8 || this.form.promotionTypeId == 9
            );
          }),
        },
        receiveNo: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 9;
          }),
        },
        roundNo: {
          required: requiredIf(function () {
            return (
              this.form.promotionTypeId == 9 ||
              this.form.promotionTypeId == 5 ||
              this.form.promotionTypeId == 7
            );
          }),
        },
        productQuantity: {
          required: requiredIf(function () {
            return (
              this.form.promotionTypeId == 5 || this.form.promotionTypeId == 7
            );
          }),
          validateMinvalue: function (value) {
            return !(
              validateMinvalue(value) &&
              (this.form.promotionTypeId == 5 || this.form.promotionTypeId == 7)
            );
          },
        },
        discountValue: {
          required: requiredIf(function () {
            return (
              this.form.promotionTypeId == 6 || this.form.promotionTypeId == 8
            );
          }),
        },
        minimumValue: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 6;
          }),
        },
        maximumLength: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 6;
          }),
        },
        maximumWidth: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 6;
          }),
        },
        maximumHeight: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 6;
          }),
        },
        maximumWeight: {
          required: requiredIf(function () {
            return this.form.promotionTypeId == 6;
          }),
        },
        conditionGroupProducts: {
          $each: {
            subConditions: {
              $each: {
                value: {
                  required: requiredIf(function () {
                    return (
                      this.form.promotionTypeId == 5 ||
                      this.form.promotionTypeId == 7 ||
                      this.form.promotionTypeId == 8 ||
                      this.form.promotionTypeId == 9
                    );
                  }),
                },
              },
            },
          },
        },
      },
      productGroups: {
        $each: {
          discount: {
            required: requiredIf(() => {
              return (
                this.form.promotionTypeId == 1 ||
                this.form.promotionTypeId == 5 ||
                this.form.promotionTypeId == 7
              );
            }),
            priceAfterDiscount(val, { price }) {
              return validateProductGroups(
                val,
                price,
                this.form.promotionTypeId,
                this.form.promotionDiscountId,
                this.firstCheck
              );
            },
          },
        },
      },
    };
  },
  methods: {
    async getDetail() {
      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (res.result == 1) {
        this.form = res.detail;
        // let startDateYear = parseInt(this.form.startDateTime.slice(0, 4))-543;
        // this.form.startDateTime = `${startDateYear}${this.form.startDateTime.substr(4)}`;
        // let endDateYear = parseInt(this.form.endDateTime.slice(0, 4))-543;
        // this.form.endDateTime = `${endDateYear}${this.form.endDateTime.substr(4)}`;

        this.form.startDateTime = `${res.detail.startDateTime.split(" ")[0]}T${
          res.detail.startDateTime.split(" ")[1]
        }.000+07:00`;
        this.form.endDateTime = `${res.detail.endDateTime.split(" ")[0]}T${
          res.detail.endDateTime.split(" ")[1]
        }.000+07:00`;

        await this.form.promotionMember.forEach((promotionMember) => {
          if (promotionMember.tiers && promotionMember.tiers.length > 0) {
            promotionMember.tiers.forEach((tier) => {
              this.defaultArray.push(tier.id);
            });
          }
        });

        this.provinceSelect = await this.province.filter((province) =>
          this.form.destinationProviceIds.includes(province.id)
        );
        this.provinceSelect = await this.provinceSelect.map(
          (item) => item.name
        );
        this.provinceSelect =
          this.provinceSelect.length > 0
            ? this.provinceSelect.join(" , ")
            : this.provinceSelect;

        this.productGroups = this.form.productDetail;
        this.$v.productGroups.$reset();

        await this.productGroups.forEach((product) => {
          product.id = product.productId;
          product.price = product.rawPrice;
        });

        if (this.form.promotionTypeId == 1) {
          if (this.form.minimumValue) {
            this.min = this.form.minimumValue;
            this.quantitySelect = 0;
          } else {
            this.min = this.form.productQuantity;
            this.quantitySelect = 1;
          }
        }
      }
    },
    setValue() {
      if (this.productGroups.length == 0 || this.all == "") {
        return;
      }
      this.isBusyProduct = true;
      this.productGroups.map((e) => {
        e.discount = this.all;
        return e;
      });

      for (let index = 0; index < this.productGroups.length; index++) {
        this.$v.productGroups.$each.$iter[index].$reset();
      }
      setTimeout(() => {
        this.isBusyProduct = false;
      }, 1000);
    },
    async getScopeType() {
      let scopeType = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/ScopeType?promotionTypeId=${this.form.promotionTypeId}`,
        null,
        this.$headers,
        null
      );
      if (scopeType.result == 1) {
        this.scopeType = scopeType.detail;
        if (this.form.conditionGroupProducts.length > 0) {
          this.form.conditionGroupProducts[0].subConditions[0].promotionScopeId =
            this.scopeType.length > 0 ? this.scopeType[0].id : "";
        }
      }
    },
    openModalProvince() {
      this.$refs.ModalProvince.show();
    },
    setProvince(val) {
      this.form.destinationProviceIds = [];
      this.form.destinationProviceIds = val;

      this.provinceSelect = this.province.filter((province) =>
        this.form.destinationProviceIds.includes(province.id)
      );
      this.provinceSelect = this.provinceSelect.map((item) => item.name);
      this.provinceSelect =
        this.provinceSelect.length > 0
          ? this.provinceSelect.join(" , ")
          : this.provinceSelect;
    },
    async getData() {
      let type = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/type`,
        null,
        this.$headers,
        null
      );
      if (type.result == 1) {
        this.type = type.detail;
        this.form.promotionTypeId = this.promotionType
          ? this.promotionType
          : this.type.length > 0
          ? this.type[0].id
          : "";
      }

      let conditionType = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/ConditionType`,
        null,
        this.$headers,
        null
      );
      if (conditionType.result == 1) {
        this.conditionType = conditionType.detail;
        this.form.conditionGroupProducts[0].subConditions[0].promotionConditionId =
          this.conditionType.length > 0 ? this.conditionType[0].id : "";
      }

      let promotionDiscount = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/PromotionDiscount`,
        null,
        this.$headers,
        null
      );
      if (promotionDiscount.result == 1) {
        this.promotionDiscount = promotionDiscount.detail;
        this.form.promotionDiscountId =
          this.promotionDiscount.length > 0 ? this.promotionDiscount[0].id : "";
      }

      let expression = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/expression`,
        null,
        this.$headers,
        null
      );
      if (expression.result == 1) {
        this.expression = expression.detail;
      }

      let membertype = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Promotion/membertype`,
        null,
        this.$headers,
        null
      );
      if (membertype.result == 1) {
        this.membertype = membertype.detail;
      }

      let province = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/promotion/Provinces`,
        null,
        this.$headers,
        null
      );
      if (province.result == 1) {
        this.province = province.detail;
        // this.province.push({ id: null, name: "เพิ่มปลายทาง", value: null });
      }
      this.quantitySelect = this.quantityType[0].id;
    },
    async onChangePromotionTypeId(value) {
      this.form.promotionTypeId = value;
      this.productGroups = [];
      this.$v.form.$reset();
      this.$v.provinceSelect.$reset();
      this.$v.productGroups.$reset();
      this.$v.min.$reset();
      this.checkMemberObj = false;
    },
    async submitForm() {
      this.firstCheck = true;
      this.$v.form.$touch();
      this.$v.min.$touch();
      this.$v.productGroups.$touch();
      this.$v.provinceSelect.$touch();
      let checkProduct = await this.checkProduct();
      let checkMember = await this.checkMember();

      if (
        this.$v.form.$error ||
        this.$v.productGroups.$error ||
        this.$v.min.$error ||
        this.$v.provinceSelect.$error ||
        !checkProduct ||
        !checkMember
      ) {
        this.checkObj = true;
        if (
          this.form.promotionTypeId !== 6 &&
          this.form.promotionTypeId !== 8
        ) {
          this.$refs.ProductList.checkValidate = true;
          this.$refs.ProductList.error = this.productGroupsError;
        }
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 250
            );
          }
        });
        return;
      }
      this.$refs.modalLoading.show();
      await this.checkForm(this.form.promotionTypeId);

      let form = { ...this.form };
      // let startDateYear = this.$moment(form.startDateTime).add(543, 'years').format("YYYY");
      // form.startDateTime = `${startDateYear}${form.startDateTime.substr(4)}`;
      // let endDateYear = this.$moment(form.endDateTime).add(543, 'years').format("YYYY");
      // form.endDateTime = `${endDateYear}${form.endDateTime.substr(4)}`;

      let result = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Promotion/save`,
        null,
        this.$headers,
        form
      );
      this.$refs.modalLoading.hide();

      if (result.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({ path: `/promotion` });
        }, 3000);
      } else {
        this.modalMessage = result.detail[0];
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    async checkMember() {
      if (this.form.promotionTypeId === 6) return true;
      if (this.form.promotionMember.length > 0) return true;
      else {
        this.checkMemberObj = true;
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 250
            );
          }
        });
        return false;
      }
    },
    setArray(index) {
      this.form.conditionGroupProducts[index].details = [];
    },
    async checkProduct() {
      if (
        this.form.promotionTypeId === 6 ||
        this.form.promotionTypeId === 8 ||
        this.form.promotionTypeId === 9
      )
        return true;
      if (this.productGroups.length > 0) {
        for (let index = 0; index < this.productGroups.length; index++) {
          if (!this.productGroups[index].discount) {
            this.productGroupsError[index] = true;
          } else this.productGroupsError[index] = false;
        }
        for (const e of this.productGroupsError) {
          if (e) return false;
        }
        return true;
      } else return false;
    },
    // handleProvince(data) {
    //   if (data == null) return;
    //   let index = this.province.findIndex((e) => {
    //     return e.id === data;
    //   });

    //   if (this.form.destinationProviceIds.length > 0) {
    //     const found = this.form.destinationProviceIds.find(
    //       (element) => element === data
    //     );
    //     if (!found) {
    //       this.form.destinationProviceIds.push(data);

    //       this.provinceSelect.push(this.province[index].name);
    //     }
    //   } else {
    //     this.form.destinationProviceIds.push(data);
    //     this.provinceSelect.push(this.province[index].name);
    //   }
    // },
    async onDataChangeTiers(val, data, tiers) {
      this.form.promotionMember = await this.membertype.map((item) => {
        let tiers = item.tiers.filter((tier) =>
          this.defaultArray.includes(tier.id)
        );
        return {
          tiers: tiers,
          roleId: item.roleId,
          roleName: item.roleName,
          roleNameTH: item.roleNameTH,
        };
      });
    },
    async checkForm(id) {
      if (id === 1) {
        delete this.form.maximumHeight;
        delete this.form.maximumLength;
        delete this.form.maximumWeight;
        delete this.form.maximumWidth;
        delete this.form.destinationProviceIds;
        delete this.form.conditionGroupProducts;
        delete this.form.expressionId;
        delete this.form.discountValue;
        delete this.form.roundNo;
        delete this.form.isApplyProductAsUsed;
      } else if (id === 5 || id === 7) {
        delete this.form.maximumHeight;
        delete this.form.maximumLength;
        delete this.form.maximumWeight;
        delete this.form.maximumWidth;
        delete this.form.destinationProviceIds;
        delete this.form.discountValue;
      } else if (id === 6) {
        delete this.form.conditionGroupProducts;
        delete this.form.productDetail;
        delete this.form.roundNo;
        delete this.form.isApplyProductAsUsed;
      } else if (id === 8) {
        delete this.form.maximumHeight;
        delete this.form.maximumLength;
        delete this.form.maximumWeight;
        delete this.form.maximumWidth;
        delete this.form.destinationProviceIds;
        delete this.form.productDetail;
        delete this.form.roundNo;
        delete this.form.isApplyProductAsUsed;
      } else if (id === 9) {
        delete this.form.maximumHeight;
        delete this.form.maximumLength;
        delete this.form.maximumWeight;
        delete this.form.maximumWidth;
        delete this.form.destinationProviceIds;
        delete this.form.code;
        delete this.form.discountValue;
        delete this.form.isApplyProductAsUsed;
      }
    },
    handleGroup(index) {
      if (index !== undefined) {
        this.form.conditionGroupProducts[index].subConditions.push({
          id: 0,
          promotionConditionId: this.conditionType[0].id,
          promotionScopeId: this.scopeType[0].id,
          value: "",
        });
      } else {
        this.form.conditionGroupProducts.push({
          id: 0,
          subConditions: [
            {
              id: 0,
              promotionConditionId: this.conditionType[0].id,
              promotionScopeId: this.scopeType[0].id,
              value: "",
            },
          ],
          productSelectTypeId: 0,
          details: [],
        });
      }
    },
    spliceGroup(index, key) {
      if (key !== undefined) {
        this.form.conditionGroupProducts[index].subConditions.splice(key, 1);
      } else {
        this.form.conditionGroupProducts.splice(index, 1);
      }
    },
    openModal(index) {
      this.$refs.ModalSku.show();
      this.$refs.ModalSku.index = index;
      this.$refs.ModalSku.selected = [];
    },
    handleData(data, i) {
      if (i !== undefined) {
        if (this.form.conditionGroupProducts[i].details.length > 0) {
          for (let index = 0; index < data.length; index++) {
            let find = this.form.conditionGroupProducts[i].details.findIndex(
              (e) => {
                return e.productId === data[index].productId;
              }
            );
            if (find === -1)
              this.form.conditionGroupProducts[i].details.push(data[index]);
          }
        } else {
          this.form.conditionGroupProducts[i].details = data;
        }

        if (this.form.promotionTypeId == 7) {
          this.productGroups = [];
          for (const x of this.form.conditionGroupProducts) {
            for (const element of x.details) {
              this.productGroups.push(element);
            }
          }
        }
      } else {
        if (this.productGroups.length > 0) {
          for (let index = 0; index < data.length; index++) {
            let find = this.productGroups.findIndex((e) => {
              return e.id === data[index].id;
            });
            if (find === -1) this.productGroups.push(data[index]);
          }
        } else this.productGroups = data;
      }
    },
    deleteData(id, index) {
      if (index !== undefined) {
        for (
          let i = 0;
          i < this.form.conditionGroupProducts[index].details.length;
          i++
        ) {
          if (
            this.form.conditionGroupProducts[index].details[i].productId === id
          ) {
            this.form.conditionGroupProducts[index].details.splice(i, 1);
          }
        }
      } else {
        for (let index = 0; index < this.productGroups.length; index++) {
          if (this.productGroups[index].productId === id) {
            this.productGroups.splice(index, 1);
          }
        }
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .gray > select {
  color: #a1a8b7 !important;
}

.error {
  border-color: red !important;
}

.role-name {
  width: 7rem;
}

label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
  width: 100%;
}

.size {
  gap: 0.5rem;
}

.text-error {
  color: #ff0000 !important;
  font-size: 14px;
}

.option-select {
  width: 7rem;
}

::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}

::v-deep .dropdown-item {
  padding: 0.75rem 1.25rem !important;
}

::v-deep .lang-dd button:focus {
  background-color: #fff !important;
  color: #4f5d73;
}

::v-deep .lang-dd ul {
  width: 100px;
}
</style>
