<template>
  <div>
    <b-modal v-model="showModal" hide-header hide-footer centered size="xl">
      <div class="w-100 p-1">
        <h4 class="text-left">เลือกจังหวัด</h4>
        <hr />
        <b-form-checkbox-group v-model="selected">
          <b-form-checkbox
            v-for="(e, index) of province"
            :key="`province-${index}`"
            :value="e.id"
            class="checkbox"
            >{{ e.name }}</b-form-checkbox
          >
        </b-form-checkbox-group>
        <div class="d-flex justify-content-end align-items-center mt-4">
          <div class="d-sm-flex">
            <b-button class="button btn-cancel ml-2" @click="hide()"
              >ย้อนกลับ</b-button
            >
            <b-button
              :disabled="selected.length == 0"
              class="btn-main ml-3"
              @click="handleData"
              >บันทึก</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    province: {
      required: false,
      type: [Array, Object],
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      timer: null,
    };
  },
  mounted: async function () {},
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleData() {
      this.$emit("handleData", this.selected);
      this.hide();
    },
  },
};
</script>

<style scoped>
::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.checkbox {
  width: 135px;
  padding-left: 3rem !important;
}

.modal-body {
  margin: auto;
  text-align: center;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
